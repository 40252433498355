<template>
  <div id="banner" class="fixed top-0 left-0 w-screen z-50 bg-white shadow-lg rounded-b-md">
    <div v-if="banner.isActive && !bannerIsClosed" class="flex justify-between items-end">
      <p class="font-robot text-black text-base px-2 pt-2 pb-4"> {{banner.message }} </p>
      <div class="bg-dark_red rounded-full w-8 h-8 flex justify-center items-center mr-2 mt-2 mb-2 flex-shrink-0">
        <button @click="closeBanner()" class="font-montserrat text-white text-lg font-bold"> X </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {},
      bannerIsClosed: false,
    };
  },
  methods: {
    getBanner() {
      fetch(`https://us-central1-eestec-backend.cloudfunctions.net/app/api/admin/banner`,
        { method: 'GET' }).then((response) => response.json().then((parsedResponse) => {
        // console.log(parsedResponse);
        this.banner = parsedResponse;
      })).catch((error) => {
        console.log(error);
      });
    },
    closeBanner() {
      //you should directly access the state like this but I am lazy
      this.bannerIsClosed = true;
    },
  },
  created() {
    this.getBanner();
    // console.log(this.$store.state);
  },
};
</script>
