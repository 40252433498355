<template>
  <div id="navbar">
    <div class="sm:hidden">
      <transition name="bounce">
        <Burger v-if="!menuIsOpen" v-on:menuOpen="handleMenuClick()" :alternative-look="alternativeLook"
                class="fixed z-50 right-8 bottom-10">
        </Burger>
      </transition>
      <transition name="sidebar">
        <Sidebar v-if="menuIsOpen" v-on:menuClose="handleMenuClick()" :alternative-look="alternativeLook"
                class="fixed top-0 left-0 z-50">
        </Sidebar>
      </transition>
    </div>
    <div class="hidden sm:block">
      <NavMenu class="absolute z-50 left-0"/>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Mobile/Sidebar.vue";
import Burger from "./Mobile/Burger.vue";
import NavMenu from "./Desktop/NavMenu.vue";

export default {
  components: {
    Sidebar,
    Burger,
    NavMenu,
  },
  data() {
    return {
      menuIsOpen: false,
      alternativeLook: false,
    };
  },
  methods: {
    handleMenuClick() {
      this.menuIsOpen = !this.menuIsOpen;
      if (this.menuIsOpen) {
        window.history.pushState('Menu', '');
      }
    },
  },
  watch: {
    $route() {
      //this has to be in the navbar and no on the burger
      // because sometimes the burger is not rendered so it
      // doesnt has access to the $router
      if (this.$router.currentRoute.path === '/'
          || this.$router.currentRoute.path === '/contact'
          || this.$router.currentRoute.path === '/apply'
          || this.$router.currentRoute.path === '/aplica') {
        this.alternativeLook = true;
      } else {
        this.alternativeLook = false;
      }
    },
  },
  created() {
    window.addEventListener('popstate', () => {
      this.menuIsOpen = false;
      window.history.replaceState('', '');
    });
  },
};
</script>

<style lang="scss" scoped>
.sidebar-enter-active, .sidebar-leave-active {
  transition: transform 300ms ease-out;
}
.sidebar-enter, .sidebar-leave-to {
  transform: translateX(100vw);
}
.burger-enter-active, .burger-leave-active {
  transition: right 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 300ms;
}
.burger-enter, .burger-leave-to {
  right: -30px;
}
</style>
