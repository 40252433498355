import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

export default {
  requestPartners: (context) => {
    Vue.http.get('https://us-central1-eestec-backend.cloudfunctions.net/app/api/eac6-partners').then(
      (response) => {
        context.commit("setPartners", response.data);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
    );
  },
};
