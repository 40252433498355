<template>
  <div id="app" class="bg-grey" :class="{'modal-is-open': $store.state.globalModule.trainingModalIsOpen}">
    <Banner v-if="$router.currentRoute.path !== '/fundraising-map'"/>
    <Navbar v-if="$router.currentRoute.path !== '/fundraising-map'"/>
      <transition name="fadeThrough">
        <router-view />
      </transition>
    <Footer class="in-transition" v-if="$router.currentRoute.path !== '/fundraising-map'"/>
    <BackToTopButton/>
  </div>
</template>

<script>
import Footer from "./components/global/Footer.vue";
import Navbar from "./components/global/Navbar.vue";
import Banner from "./components/global/Banner.vue";
import BackToTopButton from "./components/global/Desktop/BackToTopButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    Banner,
    BackToTopButton,
  },
};

</script>

<style lang="scss">
#app {
  max-width: 100vw;
  overflow-x: hidden;
  // min-height: 100vh; //this stays here so that the transition between pages works
  &.modal-is-open {
    max-height: 100vh;
    overflow-y: hidden;
  }
}

//used to style the map on the contact page
// it has to be put here because it modifies the class of an imported object
// and that must be done in a global context, not scoped like in components
.vue-map {
  border-radius: 18px;
  @media  screen and (min-width: 480px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

//this is needed because tailwind resets the list-style and color of a tags
//and so the content for articles does not render lists and links as it should
.article-data, #help-us-page {
  & ol, ul {
    list-style: inside disc!important;
  }

  & a {
    color: #0000EE!important;
  }
}

// .fadeThrough-enter-active,
// .fadeThrough-leave-active {
//   transition-duration: 300ms;
//   transition-property: all;
//   transition-timing-function: ease;
//   position: fixed;
//   // min-height: 150vh;
// }

// .fadeThrough-enter-from {
//   // transform: scale(0.92);
//   // width: 92vw;
//   opacity: 0;
//   z-index: 200;
//   position: fixed;
// }

// .fadeThrough-enter-to {
//   // opacity: 1;
//   // z-index: 20;
//   // position: fixed;
//   // width: 100%;
// }

// .fadeThrough-leave-from {
//   // opacity: 1;
//   // z-index: 10;
//   position: fixed;
// }

// .fadeThrough-leave-to {
//   opacity: 0;
//   z-index: 100;
//   position: fixed;
// }

// .fadeThrough-leave-to + .in-transition,
// .fadeThrough-leave-from + .in-transition {
//   // margin-top: 100vh;
//   display: none;
// }

// .fadeThrough-enter-to + .in-transition,
// .fadeThrough-enter-from + .in-transition {
//   display: none;
// }

#about-eestec .flicking-panel {
  @media screen and (min-width: 480px) {
    position: relative;
    &:nth-child(1) {
      top: 50px;
    }
    &:nth-child(3) {
      top: 100px;
    }
  }
}
</style>
