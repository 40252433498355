<template>
  <div
    id="to-top-button"
    class="w-10 h-10 fixed z-40 bottom-12 p-2 -right-2 rounded-full bg-darker_red hidden sm:block"
    @click="scrollTo()">
    <div class="arrow w-full h-full relative z-50"/>
  </div>
</template>

<script>
export default {
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      const toTop = document.getElementById("to-top-button");
      const windowOffset = window.pageYOffset;
      const windowSize = window.innerHeight;
      if (windowOffset > windowSize * 0.2) {
        toTop.classList.add("active");
      } else {
        toTop.classList.remove("active");
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
  #to-top-button{
    visibility: hidden;
    border: 2px solid white;
    outline-width: 1px;
    outline-color: $darker_red;
    outline-style: solid;
    transition:
      outline-offset 130ms ease-out,
      scale 130ms ease-in-out,
      transform 130ms ease-out,
      visibility 130ms ease-out;
    &:hover{
      outline-offset: 6px;
      cursor: pointer;
      scale: 1.2;
    }
  }
  .arrow{
    background-image: url('../../../assets/icons/up-arrow-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
.active {
  visibility: visible!important;
  transform: translateX(-48px);
}
</style>
