import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    children: [
      {
        path: "/departmentTrainings/:trainingName",
        name: "AboutDepartmentsTrainings",
        component: () => import("../components/about/AboutDepartmentsTrainings.vue"),
      },
    ],
  },
  // {
  //   path: "/events",
  //   name: "Events",
  //   component: () => import("../views/Events.vue"),
  //   children: [
  //     {
  //       path: ":eventName",
  //       name: "EventPage",
  //       component: () => import("../components/events/EventPage.vue"),
  //     },
  //   ],
  // },
  {
    path: "/articles",
    name: "Articles",
    component: () => import("../views/Articles.vue"),
    children: [
      {
        path: "article/:articleName",
        name: "ArticleDetailPage",
        component: () => import("../components/articles/ArticleDetailPage.vue"),
      },
    ],
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/help-us",
    name: "HelpUs",
    component: () => import("../views/HelpUs.vue"),
  },
  {
    path: "/apply",
    name: "Apply",
    component: () => import("../views/Apply.vue"),
  },
  //cuz postarile s-au facut cu /aplica nu /apply
  {
    path: "/aplica",
    name: "Aplica",
    component: () => import("../views/Apply.vue"),
  },
  {
    path: "/devs",
    name: "Devs",
    component: () => import("../views/Devs.vue"),
  },
  {
    path: "/fundraising-map",
    name: "Devs",
    component: () => import("../views/FundraisingMap.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
