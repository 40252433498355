<template>
  <div id="footer" class="flex pb-6 items-center justify-center sm:gap-8"
       :class="{'dark': alternativeLook}">
      <div class="contact-data flex flex-col items-center bg-white mx-3 mb-4 p-3
                  rounded-xl shadow-xl gap-y-1 w-1/2 sm:w-1/4 sm:relative sm:bottom-5">
        <a href="https://g.page/eestec-lc-bucharest?share"
          class="text-xs mx-3 text-center font-montserrat text-dark_grey flex flex-col items-center sm:pt-5">
          <img src="../../assets/icons/location-pin.svg" alt="Location Icon" class="w-6 my-1">
          <p>Splaiul Independentei, <br class="sm:hidden"> Nr. 313, AN204bis, UPB</p>
        </a>
        <a href="tel:+40770897452" class="text-xs font-montserrat text-dark_grey flex flex-col items-center sm:mt-4
            sm:pt-5">
          <img src="../../assets/icons/phone.svg" alt="Phone Icon" class="w-7 my-2">
          <p class="contact-info text-xs font-montserrat text-dark_grey">
            {{$t('global.footer.contactPerson')}}
          </p>
          <p>0770 897 452</p>
        </a>
      </div>
      <div class="flex flex-col self-start mt-4">
        <p class="font-montserrat font-bold text-lg mb-10 sm:text-4xl sm:relative sm:bottom-8 sm:mb-0"
          :class="{'text-white': alternativeLook,'text-darker_red': !alternativeLook}"
          v-html="$t('global.footer.callToAction')">
        </p>
        <div class="icons-container flex gap-x-3 z-10 mt-3 sm:gap-x-10">
          <a href="https://www.facebook.com/EESTEC.LC.Bucharest">
            <img src="../../assets/icons/facebook.svg" alt="Facebook Icon" class="w-9">
          </a>
          <a href="https://www.instagram.com/eestec_lc_bucharest/">
            <img src="../../assets/icons/instagram.svg" alt="Instagram Icon" class="w-9">
          </a>
          <a href="https://www.linkedin.com/company/eestec-bucharest/">
            <img src="../../assets/icons/linkedin.svg" alt="LinkedIn Icon" class="w-9">
          </a>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      alternativeLook: false,
    };
  },
  watch: {
    $route() {
      if (this.$router.currentRoute.path === '/events'
          || (this.$router.currentRoute.path === '/articles' && window.innerWidth <= 480)
          || this.$router.currentRoute.path === '/contact'
          || this.$router.currentRoute.path === '/apply'
          || this.$router.currentRoute.path === '/aplica') {
        this.alternativeLook = true;
      } else {
        this.alternativeLook = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dark::before {
  background-color: $black!important; //mi-a fost lene
}
#footer {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background-color: $grey;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 70%;
  }

  &::after {
    content: "";
    background-color: $darker_red;
    position: absolute;
    z-index: -1;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
