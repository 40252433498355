import Vue from "vue";

import VueResource from 'vue-resource';
import * as VueGoogleMaps from 'vue2-google-maps'; // used for accesing the google maps api in an element, it is not maintained anymore and only for vue2
import Vuei18n from 'vue-i18n';

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";//lasam asta aici ca altfel nu merge :D
import { Loading } from 'buefy';
import 'buefy/dist/buefy.css';

import en from './locales/en.json';
import ro from './locales/ro.json';

import App from "./App.vue";
import router from "./router";
import store from "./store";

import './assets/styles/flicking.css'; //importing flicking.css _again_ (used for firebase)
import './assets/styles/tailwind.css'; //importing tailwind

Vue.config.productionTip = false;

Vue.use(VueResource);
//this key shouldn't be fucking public but I won't do something about it
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAQLikAuA2vwuToJgv5DEWF16i5T1V9ieY', // key registered with google
  },
});

Vue.use(Flicking);
Vue.use(Vuei18n);
Vue.use(Loading);

const i18n = new Vuei18n({
  locale: 'ro',
  messages: {
    en,
    ro,
  },
  silentFallbackWarn: true,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
