<template>
    <div id="sidebar" class="relative w-screen h-screen">
        <div class="bg-black w-screen h-screen cursor-pointer fixed z-0"
             @click="closeSidebarPanel()"></div>
        <div class="sidebar-panel fixed left-0 top-0 w-4/5 h-screen z-50
                    flex flex-col gap-y-5 items-start pt-8 pb-10 px-10"
              :class="{'bg-dark_red':  !alternativeLook, 'bg-grey': alternativeLook}"
             @click="goToDevs()">
          <a href="https://eestec.net/" class="self-center">
            <img v-if="alternativeLook" src="@/assets/images/EESTEC-logo.svg" alt="EESTEC Logo">
            <img v-else src="../../../assets/images/Logo.png" alt="EESTEC Logo" class="w-28">
          </a>
          <button class="mt-12 mb-7 font-montserrat text-black font-medium"
                  @click="changeLanguage()">
            <span :class="{'text-darker_red': this.$i18n.locale == 'ro' && alternativeLook,
                           'text-white': this.$i18n.locale == 'ro' && !alternativeLook}">RO</span>
            /
            <span :class="{'text-darker_red': this.$i18n.locale == 'en' && alternativeLook,
                           'text-white': this.$i18n.locale == 'en' && !alternativeLook}">EN</span>
          </button>
          <div class="page font-montserrat"
               v-for="(page, index) in $router.options.routes.slice(0, 5)" :key="index"
               @click="goToPage(page.path)">
            <button :class="{'page-current': page.path == $router.currentRoute.path,
                             'text-white': !alternativeLook,
                             'text-black': alternativeLook}
              ">
              {{ $t(`global.navbar.pages[${index}]`) }}
            </button>
          </div>
          <a href="https://linktr.ee/eestecbucuresti">
            <button class="self-center justify-self-end font-montserrat mt-auto px-8 py-3 font-bold
                         rounded-3xl mb-10"
                  :class="{'bg-white': !alternativeLook, 'bg-black': alternativeLook,
                           'text-grey': alternativeLook, 'text-dark_red': !alternativeLook}"
                  >
            {{ $t(`global.navbar.pages[5]`) }}
          </button></a>
        </div>
        <img
          src="../../../assets/icons/white-cross.svg"
          alt="Cllose"
          class="close-button w-6 h-6 fixed z-10"
          @click="closeSidebarPanel()"
        >
    </div>
</template>
<script>
export default {
  props: ['alternativeLook'],
  data() {
    return {
      timesClicked: 0,
    };
  },
  methods: {
    closeSidebarPanel() {
      this.$emit('menuClose');
    },
    changeLanguage() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "ro";
      } else {
        this.$i18n.locale = "en";
      }
    },
    goToPage(newPath) {
      if (this.$router.currentRoute.path !== newPath) {
        this.closeSidebarPanel();
        this.$router.push(newPath);
      }
    },
    goToDevs() {
      if (this.timesClicked !== 2) {
        this.timesClicked += 1;
      } else {
        this.goToPage('/devs');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  &-current::before {
    content: "";
    position: absolute;
    width: 30px;
    left: -40px;
    top: 10%;
    bottom: 10%;
  }
  &-current.text-black::before {
    background-color: $darker_red;
  }
  &-current.text-white::before {
    background-color: $black;
  }
  &::after {
    content: "";
    position: absolute;
    top: -20%;
    left: -20%;
    right: -20%;
    bottom: -20%;
  }
}

.close-button {
  right: 10%;
  top: 40px;
  transform: translateX(50%);
}
</style>
