<template>
  <div id="burger">
    <div class="burger rounded-full flex flex-col gap-y-1 px-3 py-4 shadow-md"
         :class="{'bg-white':  alternativeLook, 'bg-dark_red':  !alternativeLook}"
          @click="handleMenuClick()">
      <div v-for="bar in [1,2,3]" :key="bar" class="w-5 h-0.5"
           :class="{'bg-dark_red':  alternativeLook, 'bg-white': !alternativeLook}"
        />
    </div>
  </div>
</template>

<script>

export default {
  props: ['alternativeLook'],
  methods: {
    handleMenuClick() {
      this.$emit('menuOpen');
    },
  },
};
</script>
