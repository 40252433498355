<template>
  <div id="nav-menu" class="flex items-center justify-around w-full top-4">
    <img
      src="../../../assets/images/Logo.png"
      alt="EESTEC Logo"
      @click="goToPage('/')"
      class="object-contain cursor-pointer w-28 h-28"
      :class="{'hidden': alternativeLook}"/>
    <img
      src="../../../assets/images/EESTEC-logo.svg"
      alt="EESTEC Logo"
      @click="goToPage('/')"
      class="object-contain cursor-pointer w-28 h-28"
      :class="{'hidden': !alternativeLook}"/>
    <div class="flex gap-x-8">
      <button
        v-for="(page, index) in $router.options.routes.slice(0, 5)" :key="index"
        @click="goToPage(page.path)"
        class="text-2xl sm:font font-montserrat"
        :class="{'text-black': alternativeLook, 'text-white': !alternativeLook}"
      >
        <p
          class="opacity-80 hover:opacity-100"
          :class="{'opacity-100 font-semibold': page.path == currentPath}">
          {{ $t(`global.navbar.pages[${index}]`) }}
        </p>
      </button>
      <a href="https://linktr.ee/eestecbucuresti"><button
        class="h-16 px-10 text-2xl font-bold rounded-full font-montserrat"
        :class="{'bg-dark_red text-white': alternativeLook, 'bg-white  text-dark_red': !alternativeLook}"
      >
        {{ $t(`global.navbar.pages[5]`) }}
      </button></a>
      <button
        @click="changeLanguage()"
        class="flex flex-col justify-center text-lg font-medium font-montserrat"
        :class="{'text-dark_red': alternativeLook, 'text-white': !alternativeLook}"
      >
        <span class="opacity-80" :class="{'opacity-100 font-semibold': this.$i18n.locale == 'ro'}">RO</span>
        <span class="opacity-80" :class="{'opacity-100 font-semibold': this.$i18n.locale == 'en'}">EN</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPath: "",
      alternativeLook: false,
    };
  },
  methods: {
    goToPage(newPath) {
      if (this.$router.currentRoute.path !== newPath) {
        this.$router.push(newPath);
      }
    },
    changeLanguage() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "ro";
      } else {
        this.$i18n.locale = "en";
      }
    },
  },
  watch: {
    $route() {
      this.currentPath = this.$router.currentRoute.path;
      if (this.$router.currentRoute.path.includes('/articles/article/')
          || this.$router.currentRoute.path.includes('/help-us')) {
        this.alternativeLook = true;
      } else {
        this.alternativeLook = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
