import Vue from "vue";
import Vuex from "vuex";
import partnersModule from './modules/partners/index';
import globalModule from './modules/global/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    partnersModule,
    globalModule,
  },
});
